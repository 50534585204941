button.link {
  font-size: 13px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

div.formGroup {
  margin-bottom: 8px;
  
  &:not(:first-child) {
    margin-top: 8px;
  }
}

div.childEntriesContainer {
  background: #f8f9fa;
  padding: 20px;
  border: 1px solid #d5dcec;
}

input.totalInput {
  background-color: transparent !important;
  border: none !important;
  padding-left: 0 !important;
}