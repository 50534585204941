.container {
  min-width: 600px;
}

.body {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 15px;

  h4 {
    font-size: 25px;
  }

  p.bodyText {
    padding-top: 15px;
    max-width: 500px;
    text-align: center;

    button {
      font-size: 13px !important;
      margin: 0 !important;
      padding: 0 !important;
      border-top-width: 0px !important;
      border-bottom-width: 0px !important;
      cursor: pointer;
    }
  }

  p.footerText {
    padding-top: 10px;
    width: 100%;
    text-align: center;
    border-top: 1px solid #c7c7c7;

    button {
      font-size: 13px !important;
      margin: 0 !important;
      padding: 0 !important;
      border-top-width: 0px !important;
      border-bottom-width: 0px !important;
      cursor: pointer;
    }
  }
}

.header {
  background-color: #fff;
  border-bottom: none;
  padding-bottom: 0px !important;

  button {
    span {
      &:first-child {
        font-size: 30px !important;
      }
    }
  }
}

.footer {
  background-color: yellow;
}