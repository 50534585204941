.react-datepicker {
  font-family: var(--font-family);
  font-weight: 600;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
  width: 100% !important;
}

.react-datepicker {
  border: 1px solid #D4D7DC;
  border-radius: 3px;
  /* box-shadow: 0 3px 0 rgb(0 0 0 / 10%); */
  z-index: 999999 !important;
}

.react-datepicker__month-container {
  padding: 10px;
}

.react-datepicker__current-month {
  text-transform: uppercase;
  height: 32px;
  text-align: center;
  background: none;
  color: #393A3D;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

.react-datepicker__day-name {
  text-transform: uppercase;
  text-align: center;
  cursor: default;
  border-radius: 0;
  font-weight: bold;
  color: #8D9096;
  font-size: 0.9em;
}

.react-datepicker__header__dropdown {
  display: none !important;
}

.react-datepicker__header {
  background-color: transparent;
}

.react-datepicker__day {
  color: #3f4248;
}

.react-datepicker__day--today {
  font-weight: 600;
  color: #FFFFFF;
  background: #BABEC5;
  border-radius: 50%;
}
.react-datepicker__day--selected {
  font-weight: 600;
  color: #FFFFFF;
  background: var(--accent-color);
  border-radius: 14px;
}

.react-datepicker__day--selected:hover {
  font-weight: 600;
  color: #FFFFFF;
  background: var(--accent-color);
  border-radius: 50%;
}

.react-datepicker__triangle {
  border-bottom-color: #FFF;
}

.react-datepicker-popper {
  z-index: 999999 !important;
}

.react-datepicker__navigation {
  padding: 25px 30px;
  margin: -20px;
}

.react-datepicker__navigation--next {
  right: 20px;
  top: 20px;
  background: url('./icons/icon-arrow-next.png') no-repeat 50% 50%;
  background-size: 8px;
  width: 25px;
  height: 25px;
  border: none;
}

.react-datepicker__navigation--next:hover {
  background: url('./icons/icon-arrow-next.png') no-repeat 50% 50% #f5f5f5;
  background-size: 8px;
}

.react-datepicker__navigation--previous {
  left: 20px;
  top: 20px;
  background: url('./icons/icon-arrow-previous.png') no-repeat 50% 50%;
  background-size: 8px;
  width: 25px;
  height: 25px;
  border: none;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-color: transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-color: transparent;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-color: transparent;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-color: transparent;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: none;
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 18px;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__navigation--previous:hover {
  background: url('./icons/icon-arrow-previous.png') no-repeat 50% 50% #f5f5f5;
  background-size: 8px;
}

.react-datepicker__day--outside-month {
  color: #c3c7cd;
}

.react-datepicker__day--disabled {
  color: #c3c7cd;
  cursor: not-allowed;
}