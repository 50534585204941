.swal2-title {
  font-weight: 700 !important;
}

.swal2-container {
  font-family: var(--font-family) !important;
}

.swal2-html-container {
  font-size: 1em !important;

  @media (max-width: 768px) {
    margin: 1em !important;
  }
}

.swal2-popup {
  padding: 38px 24px !important;

  @media (max-width: 768px) {
    padding: 24px 16px !important;
  }
}

button.swal2-cancel {
  color: #212529 !important;
  background-color: #dfe8fb !important;
  border-color: #dfe8fb !important;
}

.swal2-radio {
  flex-direction: column;
  align-items: flex-start;
  margin: 8px;
}

input.swal2-input::placeholder {
  font-size: 0.8em !important;
}

.swal2-radio label {
  cursor: pointer;
}

.swal2-radio label:hover {
  color: #100c0c;
}