.content {
  text-align: center;

  h3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.footer {
  flex: 1;
  display: flex;
  justify-content: space-between;
}