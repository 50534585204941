:root {
  --color-dark: #161b22;
  --color-white: #fff;
  --main-bg-color-white: #eff3f5;
  --main-bg-color-dark: #0d1117;
}

mark {
  background: yellow;
  color: inherit;
  padding: 0;
}

input { touch-action: none; }

.auth.theme-two .auto-form-wrapper form {
  width: 80%;
}

.display-5 {
  font-size: 1.375rem;
}

#root {
  height: 100% !important;
}

.content-wrapper {
  padding-top: var(--wrapper-padding-top);
}

.text-success {
  color: #00ab6f !important;
}

.text-purple {
  color: rgb(83, 58, 253) !important;
}

.box-shadow {
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

.tooltip {
  z-index: 10 !important;
}

.stretch-card {
  height: 100% !important;
}

.no-border {
  border-left: none !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media (min-width: 769px) {
  .content-wrapper {
    padding-top: var(--wrapper-padding-top);
    padding-bottom: 1rem;
    padding-left: calc(54px + 1rem);
    padding-right: 1rem;
    transition: padding-left .3s;
    width: 100%;
    flex-grow: 1;
  }

  footer {
    padding-left: calc(54px + 1rem);
    padding-right: 1rem;
    transition: padding-left .3s;
  }
}

.container-scroller {
  height: 100% !important;
}

.page-body-wrapper {
  height: 100%;
  background: var(--color-main-bg);
}

html,
body {
  margin: 0;
  height: 100%;
  color: var(--color-text-primary);
  background-color: var(--color-main-bg);
}

.breadcrumb {
  background-color: white;
}

.custom-select {
  background: white;
}

.badge-default {
  border: 1px solid #6c757d;
  color: #fff;
  background-color: #6c757d;
}

.badge-outline-default {
  color: #6c757d;
  border: 1px solid #6c757d;
}


span.input-group-text {
  height: auto;
}

.text-yellow {
  color: #E90 !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px; 
  } 
}

.card {
  border: var(--card-border);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: var(--card-bg-color);
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--title-color);
}


.form-control {
  line-height: 18px;
  font-weight: 500;
  background-color: var(--input-bg-color);
  border: var(--form-control-border);
  border-radius: 4px;
}

.form-control:focus {
  color: var(--form-control-focus-color);
  background-color: var(--form-control-background-color);
  border-color: var(--form-control-border-color);
}

.table {
  color: var(--table-color);
}

/* .table-responsive {
  overflow-x: visible;
} */

.table thead th {
  color: var(--th-color);
  border-bottom: none;
}

.table thead th:first-child {
  border-top-left-radius: 4px;
}

.table thead th:last-child {
  border-top-right-radius: 4px;
}

.table th, .table td {
  border-top: var(--table-th-td-border-top);
}

.table-striped tbody tr:nth-of-type(odd) {
  background: var(--tr-color-hover);
}

.table-hover tbody tr:hover {
  background: var(--tr-color-hover);
}

/* 
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #eef4ff;
  background-color: rgba(114, 124, 245, 0.1);
  border-color: #262f43;
} */

.react-datepicker-popper {
  z-index: 2 !important;
}

.icon-spin {
  -webkit-animation: icon-spin .8s infinite linear;
          animation: icon-spin .8s infinite linear;
}

.auth.theme-two .auto-form-wrapper form .form-group {
  margin-bottom: 1rem;
}

.navbar {
  padding: 0;
}

.list-group-item.active {
  z-index: 1;
}

.form-check {
  margin-bottom: 0;
  margin-top: 0;
}

/* React Switch */

.react-switch-bg > div:nth-child(1) > svg {
  display: none !important;
}

.react-switch-bg > div:nth-child(2) > svg {
  display: none !important;
}

/* .react-switch-bg {
  width: 25px !important;
  height: 16px !important;
}



.react-switch-handle {
  width: 14px !important;
  height: 14px !important;
} */

/* HIGHCHARTS CHARTS */

.highcharts-container {
  margin: 0;
  padding: 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  width: auto !important;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.print-only {
  display: none;
}

.tox .tox-statusbar__branding svg {
  display: none;
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    background-color: white;
  }

  footer {
    display: none;
  }

  .no-print {
    display: none !important;
  }

  .print-only {
    display: block;
  }

  .report-wrapper-column {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }

  .content-wrapper {
    padding: 0;
  }
}

@page {
  size: A4;
  margin: 5mm;
}