.headerCell {
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  padding: 15px 30px !important;
  position: sticky; 
  top: 0; 
  z-index: 1;
  background-color: var(--th-bg-color);
}

.headerCell::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #d5dcec;
  box-shadow: inset 0px 25px 1px -10px #d5dcec
  
}

.footerCell {
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  padding: 15px 30px !important;
  font-weight: 600 !important;
  color: #223C61;
  position: sticky; 
  bottom: 0; 
  z-index: 1;
  background-color: var(--th-bg-color);
  border-top: none !important;
}

.footerCell::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--th-border-color);
  box-shadow: inset 0px 25px 1px -10px #d5dcec
}

.cell {
  font-size: 13px;
  line-height: 13px;
  font-weight: normal;
  padding: 15px 30px !important;
}

.active {
  background: #eceef1;
}

.emptyColumn {
  background-color: var(--th-bg-color);
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--th-border-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.emptyMessage {
  margin-top: 1rem;
  font-size: 1rem;
}

@media print {
  .noPrint {
    display: none;
  }  
}
