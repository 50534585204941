.react-contexify {
  font-family: 'Poppins' !important;
  padding: 0 !important;
  min-width: 225px !important;
}

.react-contexify__item__content {
  padding: 8px 12px;
  font-size: 13px;
  font-weight: 500;
  /* color: #8d9096 !important; */
  margin: 5px;
  border-radius: 4px;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content, 
.react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 1);
}

.react-contexify__submenu {
  max-height: 300px;
  overflow-y: auto;
}


.react-contexify__item__content svg {
  color: #6d73ef;
  margin-right: 14px !important;
}

.react-contexify__theme--company-switcher {
  min-width: 380px !important;
  padding: 8px !important;
  color: rgb(42, 46, 52) !important;
  box-shadow: 0, 0 1px 10px 0 rgba(0, 0, 0, .25) !important;
}

.react-contexify__theme--avatar-menu {
  max-width: 200px !important;
  padding: 8px !important;
  color: rgb(42, 46, 52) !important;
  box-shadow: 0, 0 1px 10px 0 rgba(0, 0, 0, .25) !important;

  .react-contexify__item__content svg {
    color: rgb(79, 87, 98) !important;
    margin-right: 14px !important;
  }
}

.react-contexify__theme--company-switcher .react-contexify__item__content svg {
  color: rgb(79, 87, 98);
}

.react-contexify__theme--company-switcher .react-contexify__item__content svg.no-margin-right {
  margin-right: 0 !important;
}

.react-contexify__theme--company-switcher .react-contexify__item__content svg.with-color {
  color: #237b0a !important;
}
