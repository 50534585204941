/* Privacy mode CSS variables */
:root {
  --privacy-filter: none; /* Default: no privacy */
  --privacy-content-visibility: visible; /* Default: content is visible */
}

.privacy-mode-active {
  --privacy-filter: blur(8px); /* When privacy mode is active */
  --privacy-content-visibility: hidden; /* When privacy mode is active, hide original content */
}

.financial-value {
  filter: var(--privacy-filter);
  position: relative;
}

/* Alternative mask approach using data-mask attribute */
.privacy-mode-active .financial-value[data-mask="true"]::before {
  content: "••••••";
  position: absolute;
  left: 0;
  visibility: visible;
}

.privacy-mode-active .financial-value[data-mask="true"] {
  visibility: var(--privacy-content-visibility);
}
