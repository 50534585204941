.container {  
  @media print {
    font-family: var(--font-family) !important;
  }
}

.companyName {
  text-transform: uppercase;
}

.reportName {

  @media print {
    color: black;
  }
}
