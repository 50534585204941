.month-picker > .rmp-container .rmp-popup.light {
  background-color: #fff !important;
  /* border: 1px solid #ccc !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important; */
}

.month-picker > .rmp-container .rmp-popup {
  width: 15rem !important;
}

@media (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup {
    width: 100% !important;
  }
}

.month-picker > .rmp-container .rmp-popup .rmp-pad {
  width: 15rem !important;
}

@media (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad {
    width: 100% !important;
  }
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
  background-color: #f5f5f5 !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
  height: 38px !important;
  line-height: 38px !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 38px !important;
  margin-bottom: 0 !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad ul {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:not(.prev, .next) {
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 100% !important;
  text-transform: uppercase;
  line-height: 45px;
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, 
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background: #e3e5e8 !important;
  color: #2ca01c !important;
}

