.react-calendar {
  border: 0;
  width: auto;
  font-family: 'Poppins';
  padding-left: 16px;
  padding-right: 16px;
}

.react-calendar__navigation__label__labelText {
  /* display: block; */
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-text-primary);
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 12px;
  color: var(--color-text-primary);
}

.react-calendar__month-view__days__day {
  font-size: 13px;
  color: #8D9096;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background: var(--color-text-primary-light);
  border-radius: 4px;
}

.react-calendar__tile--now:hover {
  background: #e6e6e6;
}

.react-calendar__tile--now {
  background: var(--color-text-primary-light);
  color: var(--color-text-primary);
  border-radius: 4px;
}

.react-calendar__tile--active {
  background: var(--accent-color-light) !important;
  color: var(--accent-color);
  border-radius: 4px;
}

div.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.transaction-flag {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  text-align: center;
  background-color: #FF8000 !important;
}

.expense-flag {
  background-color: #DB504A !important;
}

.income-flag {
  background-color: #4CCEAC  !important;
}

.transfer-flag {
  background-color: #0000FF !important;
}

.dummy-flag {
  background-color: transparent !important;
}