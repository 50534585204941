.swal2-title {
  font-weight: 700 !important;
}

.swal2-container {
  font-family: var(--font-family) !important;
}

button.swal2-cancel {
  color: #212529 !important;
  background-color: #dfe8fb !important;
  border-color: #dfe8fb !important;
}

.swal2-checkbox, .swal2-radio {
  font-size: 14px !important;
}